import { NextPageContext } from 'next';

import { ESearchFilters } from '@/constants/searchFilters';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { IStore } from '@/utility/redux/store';

// If pathname is given, will attempt to redirect to search with the appropriate rental_category filter.
export const redirectToSearch = (
  { res, query }: Pick<NextPageContext, 'res' | 'query'>,
  pathname?: string,
) => {
  let rentalCategory = ERentalCategory.RV;
  if (pathname?.startsWith('/stay')) {
    rentalCategory = ERentalCategory.STAY;
  } else if (pathname?.startsWith('/campsite') || pathname?.startsWith('/campground')) {
    // TODO: when campgrounds are fully enabled, redirect to campground search instead
    rentalCategory = ERentalCategory.STAY;
  }

  res?.writeHead(307, {
    Location: `/rv-search?address=${encodeURI(
      query.location?.toString().replace(/_/g, ' ') || '',
    )}&${encodeURI(ESearchFilters.RENTAL_CATEGORY)}=${rentalCategory}`,
  });
  res?.end();

  return { error: { statusCode: 307 } };
};

type Ctx = NextPageContext & { reduxStore: IStore };
type Error = { error: { statusCode: number } };
// This wrapper is intended to wrap `getInitialProps` so that if it throws,
// we will automatically redirect to search. In the future this wrapper may take
// specific statuses and redirects to perform as needed so the behavior
// can be specified by the caller.
export const withRedirectToSearch = <Props = Error>(
  getInitialProps: (ctx: Ctx) => Promise<Props>,
) => {
  return async (context: Ctx): Promise<Props | Error> => {
    const { res, query, pathname } = context;
    try {
      const nextResult = await getInitialProps(context);
      return nextResult;
    } catch (err) {
      if (res) {
        if (['401', '404'].includes(err.status)) {
          return redirectToSearch({ res, query }, pathname);
        }
        res.statusCode = 500;
      }
      return { error: { statusCode: 500 } };
    }
  };
};
