import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

import { getReviewsData, IGetReviewsData } from '../listing/reviews';

type TListingData = TRootState['campgroundListing']['data'];
type TReviews = TRootState['reviews']['all']['data'];
type TQueryParams = TRootState['queryParams'];

export const getCampgroundAllReviews = createSelector<
  TRootState,
  TListingData,
  TReviews,
  TQueryParams,
  IGetReviewsData | null
>(
  state => state.campgroundListing.data,
  state => state.reviews.all.data,
  state => state.queryParams,
  getReviewsData,
);
