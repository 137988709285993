import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IImage } from '@/services/types/search/rentals/id';
import { getFormattedImages, transformCampgroundImagesData } from '@/utility/rentals';

type TListingData = TRootState['listing']['data'];
type TCampgroundListingData = TRootState['campgroundListing']['data'];

export const getGallery = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getFormattedImages>
>(state => state.listing.data, getFormattedImages);

export const getCampgroundGallery = createSelector<TRootState, TCampgroundListingData, IImage[]>(
  state => state.campgroundListing.data,
  data => {
    const images = data?.images || [];
    return transformCampgroundImagesData(images, data?.odn_id);
  },
);

export const getCampgroundPrimaryImage = createSelector<
  TRootState,
  TCampgroundListingData,
  IImage | undefined
>(
  state => state.campgroundListing.data,
  data => {
    const images = data?.images || [];
    const primaryImage = images.find(i => i.primary);
    if (!primaryImage) return;
    return transformCampgroundImagesData([primaryImage])[0];
  },
);
