import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import DatePickerModal from '@/components/switchback/DatePickerModal/DatePickerModal';
import Price from '@/components/switchback/Price/Price';
import BillModuleDates, {
  EBillModuleDatesVariants,
} from '@/components/ui/BillModule/BillModuleDates/BillModuleDates';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IPricingInformation } from '@/redux/selectors/listing/bill';

interface IMiniBookerProps {
  isCampground?: boolean;
  billPrice: IPricingInformation;
  ctaLabel: string;
  dateFrom?: Date;
  dateTo?: Date;
  disabledDays?: {
    from: Date;
    to: Date;
  }[];
  hasInvalidDates?: boolean;
  isUnavailable?: boolean;
  minimumDays: number;
  onClickRequestToBook: () => void;
  onSelectDate?: (from?: Date, to?: Date) => void;
}

const MiniBooker: React.FC<IMiniBookerProps> = ({
  isCampground,
  ctaLabel,
  dateFrom,
  dateTo,
  disabledDays,
  billPrice,
  minimumDays,
  isUnavailable,
  hasInvalidDates,
  onClickRequestToBook,
  onSelectDate,
}) => {
  const intl = useIntl();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const { isAboveTablet, isAboveDesktop } = useBreakpoint();
  const calendarWarning = intl.formatMessage(
    {
      defaultMessage:
        'This rental has a {minimumDays} {priceType, select, day {day} other {night}} minimum.',
      id: 'Y35/4X',
      description: 'Listing Page > Bill Module',
    },
    { minimumDays, priceType: billPrice.period },
  );
  const hasDatesSelected = Boolean(dateFrom && dateTo);

  const handleHideCalendar = () => setIsCalendarOpen(false);

  const handleClearDates = useCallback(() => {
    onSelectDate?.();
    handleHideCalendar();
  }, [onSelectDate]);

  const handleClickCallToAction = useCallback(() => {
    if (!hasDatesSelected || hasInvalidDates) {
      setIsCalendarOpen(true);
      return;
    }

    onClickRequestToBook();
  }, [hasDatesSelected, hasInvalidDates, onClickRequestToBook]);

  return (
    <div className="flex items-center whitespace-nowrap">
      {billPrice && isAboveTablet && (
        <div className="mr-4">
          <Price {...billPrice} isCampground={isCampground} testId="request-booking-price" />{' '}
        </div>
      )}
      {isAboveDesktop && (
        <div className="ml-2">
          <BillModuleDates
            calendarWarning={calendarWarning}
            disabledDays={disabledDays}
            onSelectDate={onSelectDate}
            from={dateFrom}
            to={dateTo}
            minimumDays={minimumDays}
            isUnavailable={isUnavailable}
            variant={EBillModuleDatesVariants.small}
            testId="minibooker"
          />
        </div>
      )}
      <Button
        className="ml-2 whitespace-nowrap"
        onClick={handleClickCallToAction}
        variant={EButtonColorVariant.Primary}
        label={ctaLabel}
        data-testid="request-booking-btn"
      />
      <div ref={calendarRef}>
        <DatePickerModal
          disabledDays={disabledDays}
          initialFrom={dateFrom}
          initialTo={dateTo}
          minimumDays={minimumDays}
          onSelectDate={onSelectDate}
          onConfirmDates={handleHideCalendar}
          onClearDates={handleClearDates}
          onDismiss={handleHideCalendar}
          relativeTo={calendarRef.current}
          show={isCalendarOpen}
          warning={minimumDays ? calendarWarning : undefined}
        />
      </div>
    </div>
  );
};

export default MiniBooker;
