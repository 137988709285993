import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import throttle from 'lodash/throttle';
import React, { MouseEvent, RefObject, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import { useStickyToHeaderBottom } from '@/hooks/useStickyToHeaderBottom';
import { IPricingInformation } from '@/redux/selectors/listing/bill';
import { getIsListingPublished } from '@/redux/selectors/listing/page';
import { getIsListingAllowedByWhitelist, getNearbyRentalsURL } from '@/redux/selectors/stayStates';

import MiniBooker from '../MiniBooker/MiniBooker';
import css from './StickyNav.module.css';

interface IStickyNavProps {
  isCampground?: boolean;
  billPrice: IPricingInformation;
  checkoutURL?: string;
  ctaLabel: string;
  dateFrom?: Date;
  dateTo?: Date;
  disabledDays?: {
    from: Date;
    to: Date;
  }[];
  items: {
    label: string;
    anchor: string;
  }[];
  hasInvalidDates?: boolean;
  isUnavailable?: boolean;
  lowerSectionRef: RefObject<HTMLDivElement>;
  minimumDays: number;
  upperSectionRef: RefObject<HTMLElement>;
  onClickRequestToBook: () => void;
  onClickAnchor?: (anchor: string) => void;
  onSelectdate?: (from?: Date, to?: Date) => void;
}

export const STICKY_NAV_OFFSET = 100;

const StickyNav: React.FC<IStickyNavProps> = ({
  isCampground,
  ctaLabel,
  upperSectionRef,
  lowerSectionRef,
  items,
  dateTo,
  dateFrom,
  disabledDays,
  billPrice,
  minimumDays,
  onSelectdate,
  onClickRequestToBook,
  onClickAnchor,
  hasInvalidDates,
}) => {
  const intl = useIntl();
  const isListingAllowedByWhitelist = useSelector(getIsListingAllowedByWhitelist);
  const isPublished = useSelector(getIsListingPublished);
  const href = useSelector(getNearbyRentalsURL);

  const [isVisible, setIsVisible] = useState(false);
  const [isBookerVisible, setIsBookerVisible] = useState(false);
  const [activeItem, setActiveItem] = useState<string>();
  const [availableItems, setAvailableItems] = useState(items);

  useEffect(() => {
    const onScroll = throttle(() => {
      const upperSectionBottom = upperSectionRef.current?.getBoundingClientRect().bottom;
      const lowerSectionTop = lowerSectionRef.current?.getBoundingClientRect().top;

      if (upperSectionBottom && upperSectionBottom <= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (lowerSectionTop && lowerSectionTop <= 0) {
        setIsBookerVisible(true);
      } else {
        setIsBookerVisible(false);
      }

      const itemInView = availableItems.find(item => {
        const el = document.getElementById(item.anchor);
        const rect = el?.getBoundingClientRect();
        if (!rect) {
          return false;
        }
        return rect.top < STICKY_NAV_OFFSET && rect.bottom > STICKY_NAV_OFFSET;
      });

      if (itemInView) {
        setActiveItem(itemInView.anchor);
      }
    }, 50);

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [availableItems, upperSectionRef, lowerSectionRef]);

  useEffect(() => {
    const availableNavItems = items.filter(item => {
      return document.getElementById(item.anchor);
    });

    setAvailableItems(availableNavItems);
  }, [items]);

  const onNavClick = (anchor: string) => (e: MouseEvent) => {
    e.preventDefault();
    const element = document.getElementById(anchor);
    if (element) {
      const currentScroll = window.scrollY;
      window.location.hash = anchor;
      window.scrollTo({ top: currentScroll });

      window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top + window.pageYOffset - STICKY_NAV_OFFSET,
      });
    }

    if (onClickAnchor) onClickAnchor(anchor);
  };

  const { stickyTopPositionRef } = useStickyToHeaderBottom();

  return (
    <div
      data-visible={isVisible}
      data-testid="sticky-header"
      className={`hidden py-7 lg:py-0 md:block fixed w-full bg-white transform -translate-y-full transition-transform duration-200 ease-in-out border-b border z-50 ${css.bar}`}
      ref={stickyTopPositionRef}>
      <div className="grid grid-cols-main container-main gap-x-default">
        <div className="flex items-center col-span-9 col-start-1">
          {availableItems.map(({ label, anchor }) => (
            <span key={label} className="inline-block pr-7 last:pr-0 lg:pr-3 xl:pr-7">
              <a
                href={`#${anchor}`}
                onClick={onNavClick(anchor)}
                data-testid={`${anchor}-btn`}
                data-current={activeItem === anchor}
                className={`text-gray-500 highlight autoType400 lg:text lg:autoType300 xl:highlight xl:autoType400 ${css.link}`}>
                {label}
              </a>
            </span>
          ))}
        </div>
        {!isPublished || !isListingAllowedByWhitelist ? (
          <div className="justify-end hidden lg:flex lg:col-start-10 xl:col-start-11 lg:col-span-7 xl:col-span-6">
            <div
              className={`py-4 transform -translate-y-full transition-transform duration-500 ease-in-out ${css.booker}`}
              data-visible={isBookerVisible}>
              <div data-testid="unpublished-nav" className="flex items-center whitespace-nowrap">
                <Heading level={2} className="mb-3 highlight autoType800">
                  <FormattedMessage
                    defaultMessage="Oops, this listing isn't active right now."
                    id="vS8dFg"
                    description="Listing Page > Bill Module"
                  />
                </Heading>

                <Button
                  className="ml-4 whitespace-nowrap"
                  href={href}
                  variant={EButtonColorVariant.Primary}
                  label={intl.formatMessage({
                    defaultMessage: 'Explore other options',
                    id: 'f0m/Sl',
                  })}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="justify-end hidden lg:flex lg:col-start-10 xl:col-start-11 lg:col-span-7 xl:col-span-6">
            <div
              className={`py-4 transform -translate-y-full transition-transform duration-500 ease-in-out ${css.booker}`}
              data-visible={isBookerVisible}>
              <MiniBooker
                isCampground={isCampground}
                ctaLabel={ctaLabel}
                dateFrom={dateFrom}
                dateTo={dateTo}
                disabledDays={disabledDays}
                billPrice={billPrice}
                isUnavailable={false}
                hasInvalidDates={hasInvalidDates}
                minimumDays={minimumDays}
                onSelectDate={onSelectdate}
                onClickRequestToBook={onClickRequestToBook}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StickyNav;
