import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Heading from '@/components/switchback/Heading/Heading';
import { useRouter } from '@/hooks/useRouter';
import { trackListingSeeAllReviewsCheckedEvent } from '@/services/analytics/listings';
import { ERentalType } from '@/services/analytics/types';
import { ECampsiteCategoryType } from '@/services/types/search/rentals/id';

import Rating from '../../Rating/Rating';

export interface IProps {
  averageRating: number;
  totalReviews: number;
  showSeeAllButton?: boolean;
  rentalId?: number;
  isCampground?: boolean;
  isCampsite?: boolean;
  campsiteCategoryType?: ECampsiteCategoryType;
  isStay?: boolean;
}

const ReviewsHeader: React.FC<IProps> = ({
  isCampground,
  isCampsite,
  campsiteCategoryType,
  isStay,
  averageRating,
  totalReviews,
  showSeeAllButton = false,
  rentalId,
}) => {
  const router = useRouter();
  const { query } = router;
  const urlPrefix = isCampsite
    ? '/campsite-rental/'
    : isCampground
      ? '/campground-rental/'
      : isStay
        ? '/stay-rental/'
        : '/rv-rental/';
  const href = `${urlPrefix}${query.location}/${query.slug}/reviews`;

  const handleSeeAllReviews = () => {
    if (rentalId) {
      trackListingSeeAllReviewsCheckedEvent({
        rentalID: rentalId,
        rentalType: (() => {
          if (isCampground) return ERentalType.CAMPGROUND;
          if (isCampsite) return campsiteCategoryType || ERentalType.CAMPSITE;
          if (isStay) return ERentalType.STAY;
          return ERentalType.RV;
        })() as ERentalType,
        isCampground: !!isCampground || !!isCampsite,
        reviewsCount: totalReviews,
        reviewsAverage: averageRating,
      });
    }
    const queryString = typeof window !== 'undefined' ? window.location.search : '';
    router.push(href + queryString, undefined, { shallow: true });
  };

  return (
    <div
      className="flex items-center justify-between mb-4 lg:flex-col lg:items-start"
      data-testid="listing-reviews-module">
      <div className="flex items-baseline">
        <Heading level={2} className="hidden text-2xl highlight md:block">
          <FormattedMessage defaultMessage="Customer reviews" id="SSbMd+" />
        </Heading>
        <Heading level={2} className="block text-2xl highlight md:hidden">
          <FormattedMessage defaultMessage="Reviews" id="dUxyza" />
        </Heading>
        <span className="flex ml-2 font-bold text-gray-800 text-300 md:text-800 md:highlight md:font-normal">
          {'('}
          <Rating iconClassName="h-3 w-3 md:h-6 md:w-6" number={averageRating} />
          {')'}
        </span>
      </div>
      {showSeeAllButton && (
        <Button
          onClick={handleSeeAllReviews}
          className="lg:mt-4"
          data-testid="listing-all-reviews"
          variant={ButtonVariants.primaryOutlined}
          size={ButtonSize.xsmall}>
          <FormattedMessage
            defaultMessage="See all {totalReviews} reviews"
            id="Vp+REp"
            values={{ totalReviews }}
          />
        </Button>
      )}
    </div>
  );
};

export default ReviewsHeader;
