import { useEffect, useMemo, useRef } from 'react';

import { breakpoint } from '@/constants/breakpoint';

import useWindowSize from './useWindowSize';

export const useStickyToHeaderBottom = (defaultTopPosition = '0px') => {
  const stickyTopPositionRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowSize().width || 0;
  const isMobile = useMemo(
    () => (windowWidth ? windowWidth < breakpoint.md : false),
    [windowWidth],
  );
  useEffect(() => {
    const handleScroll = () => {
      const headers = document.getElementById('global-headers');
      const headersBottomPosition = headers?.getBoundingClientRect().bottom.toString();
      if (
        stickyTopPositionRef.current &&
        stickyTopPositionRef.current.style.top !== headersBottomPosition
      ) {
        stickyTopPositionRef.current.style.top = isMobile
          ? defaultTopPosition
          : headersBottomPosition + 'px';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [defaultTopPosition, isMobile]);

  return { stickyTopPositionRef };
};
