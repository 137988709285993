import BaseModal from '@material-ui/core/Modal';
import Head from 'next/head';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Rating from '@/components/route/listing/Rating/Rating';
import Avatar from '@/components/switchback/Avatar';
import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel from '@/components/switchback/Carousel/Carousel';
import { CARET_LARGE, CLOSE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import formatImageURL from '@/utility/formatImageURL';

import css from './PhotoPreviewModal.module.css';
import { IReview } from './PhotosFromTravelers';

interface IProps {
  open?: boolean;
  reviews: IReview[];
  activeSlide?: number;
  onClose: () => void;
}

const backdropProps = {
  style: {
    backgroundColor: 'hsla(0, 0%, 0%, 0.9)',
  },
};

const PhotoPreviewModal: React.FC<IProps> = ({
  open = false,
  reviews,
  activeSlide = 0,
  onClose,
}) => {
  const intl = useIntl();
  const images = reviews.map(review => review.images).flat();
  const [currentIndex, setCurrentIndex] = useState<number>(activeSlide || 0);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [nextImageLink, setNextImageLink] = useState('');
  const slider = useRef<never>(null);

  const preLoadImages = (_: number, index: number) => {
    const nextImage = images[index + 1];
    if (!nextImage) return;
    const transformedSrc = formatImageURL(nextImage, 'default', 'fill');
    setNextImageLink(transformedSrc);
  };

  const getFormattedDate = (date: Date) =>
    intl.formatDate(date, {
      month: 'long',
      year: 'numeric',
    });

  const afterSlideChange = (index: number) => {
    setCurrentIndex(index);
  };

  const responsive = [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
      },
    },
  ];

  return (
    <>
      <Head key="imagepreload">
        <link href={nextImageLink} rel="preload" as="image" />
      </Head>
      <BaseModal
        BackdropProps={backdropProps}
        open={open}
        onClose={onClose}
        style={{ zIndex: 1400 }}>
        <div className="h-full">
          <div className="absolute z-10 top-3 right-3 md:top-8 md:right-8">
            <Button
              aria-label={intl.formatMessage({ defaultMessage: 'Close gallery', id: '/q2ubh' })}
              data-testid="lightbox-close-btn"
              shape={ButtonShape.circle}
              variant={ButtonVariants.whiteOutlined}
              size={ButtonSize.small}
              onClick={onClose}>
              <Icon name={CLOSE} size={IconSize.normal} />
            </Button>
          </div>

          <div className="relative h-full grid grid-cols-main container-main gap-x-default">
            <div className={css.carouselWrapper}>
              <div className={css.title}>
                {currentIndex + 1} of {images.length}
              </div>
              <Carousel
                ref={slider}
                componentName="gallery-overlay"
                activeSlide={activeSlide}
                className={`${css.photosList} animate-fadeIn`}
                afterSlideChange={afterSlideChange}
                beforeSlideChange={preLoadImages}
                infinite
                lazyLoad="ondemand"
                responsive={responsive}
                arrowVariant={[ArrowVariants.inside, ArrowVariants.outside]}>
                {reviews.map((review: IReview) =>
                  review.images?.map((image, index) => (
                    <div className={css.reviewContent} key={image}>
                      <figure>
                        <ResponsiveImage
                          src={image}
                          className={css.photo}
                          alt={intl.formatMessage(
                            { defaultMessage: 'Review photo {photoPosition}', id: 'E6Wrwl' },
                            { photoPosition: index + 1 },
                          )}
                        />
                      </figure>
                      <div
                        className={css.reviewDetails}
                        data-expanded={expanded}
                        onClick={() => setExpanded(!expanded)}>
                        <div className={css.renterProfile}>
                          <Icon className={css.toggleIcon} name={CARET_LARGE} />
                          <Avatar
                            className={css.reviewAvatar}
                            name={review.name}
                            photoUrl={review.photoUrl}
                            size="128"
                          />
                          <div>
                            <b>{review.name}</b>
                            <span>{getFormattedDate(review.date)}</span>
                            {review.ratingNumber !== undefined && (
                              <Rating
                                className="font-medium text-gray-800 text body-sm"
                                number={review.ratingNumber}
                              />
                            )}
                          </div>
                        </div>
                        <div className={css.reviewText}>{review.review}</div>
                      </div>
                    </div>
                  )),
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default PhotoPreviewModal;
