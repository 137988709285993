import { TIconName } from '@outdoorsyco/bonfire';
import { createSelector } from 'reselect';

import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import {
  AMENITIES_FOR_CAMPGROUND_ICONS,
  AMENITIES_FOR_CAMPSITES_ICONS,
  AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_ICONS,
  AMENITIES_FOR_STAY_ICONS,
  AMENITIES_ICONS,
} from '@/constants/amenities';
import { ELocationDetails, LOCATION_DETAILS_ICONS } from '@/constants/locationDetails';
import { TRootState } from '@/redux/rootReducer';
import { ICampgroundFeatures } from '@/services/types/search/campgrounds/id';
import { getIntl } from '@/utility/i18n';
import { IAmenityList, mapAmenities } from '@/utility/mapAmenities';

import { getIsCampsite } from './campsite';
import { getIsStay } from './listingHeader';

type TListingData = TRootState['listing']['data'];

export interface ICategory {
  id: string;
  name: string;
}

export interface IAmenity {
  available: boolean;
  category: string;
  id: string;
  name: string;
  icon?: ICONS;
  bonfireIcon?: TIconName;
  unavailableIcon?: ICONS;
}

export const getAmenities = (state: TRootState): IAmenityList => {
  return mapAmenities(
    state.listing.data?.catalog?.processed_amenities,
    state.listing.data?.catalog?.amenities_categories,
    AMENITIES_ICONS,
  );
};

export const getAmenitiesForListing = createSelector<
  TRootState,
  boolean,
  boolean,
  TRootState['listing']['data'],
  IAmenityList
>(
  getIsCampsite,
  getIsStay,
  state => state.listing.data,
  (isCampsite, isStay, listing) => {
    if (isCampsite) {
      return mapAmenities(
        listing?.campsite_category_catalog?.processed_amenities,
        listing?.campsite_category_catalog?.amenities_categories,
        listing?.campsite_category?.category_type === 'stationary_camper_site'
          ? AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_ICONS
          : AMENITIES_FOR_CAMPSITES_ICONS,
      );
    }

    if (isStay) {
      return mapAmenities(
        listing?.stay_catalog?.processed_amenities,
        listing?.stay_catalog?.amenities_categories,
        AMENITIES_FOR_STAY_ICONS,
      );
    }

    const mappedAmenities = mapAmenities(
      listing?.catalog?.processed_amenities,
      listing?.catalog?.amenities_categories,
      AMENITIES_ICONS,
    );

    const hookupElectric = mappedAmenities.list.find(amenity => amenity.id === 'hookup_electric');

    if (hookupElectric && hookupElectric.available && listing?.vehicle_amps) {
      const intl = getIntl();

      hookupElectric.name += ` (${listing.vehicle_amps} ${intl.formatMessage({
        defaultMessage: 'Amps',
        id: 'tXLV+z',
      })})`;
    }

    return mappedAmenities;
  },
);

export const getCampsiteCampgroundAmenities = (state: TRootState): IAmenityList => {
  return mapAmenities(
    state.listing.data?.campground_catalog?.processed_amenities,
    state.listing.data?.campground_catalog?.amenities_categories,
    AMENITIES_FOR_CAMPGROUND_ICONS,
  );
};

export const getCampgroundAmenities = (state: TRootState): IAmenityList => {
  return mapAmenities(
    state.campgroundListing.data?.campground_catalog?.processed_amenities,
    state.campgroundListing.data?.campground_catalog?.amenities_categories,
    AMENITIES_FOR_CAMPGROUND_ICONS,
  );
};

export const getCampgroundLocationDetailsAmenityList = createSelector<
  TRootState,
  TListingData,
  IAmenityList
>(
  state => state.listing.data,
  data => {
    const intl = getIntl();

    if (!data) {
      return {
        categories: [],
        list: [],
      };
    }

    const categories = [
      {
        id: 'scenery',
        name: intl.formatMessage({ defaultMessage: 'Scenery', id: 'ky9r22' }),
      },
      {
        id: 'activities',
        name: intl.formatMessage({ defaultMessage: 'Activities', id: 'UmEsZF' }),
      },
    ];

    const activitesList = (data.campground_catalog?.activity_features || []).map(activity => ({
      category: 'activities',
      available: Boolean(
        data.campground?.features?.[activity.feature as keyof ICampgroundFeatures],
      ),
      id: activity.feature,
      name: activity.name,
      bonfireIcon: LOCATION_DETAILS_ICONS[activity.feature as ELocationDetails],
    }));

    const sceneryList = (data.campground_catalog?.scenery_features || []).map(scenery => ({
      category: 'scenery',
      available: Boolean(data.campground?.features?.[scenery.feature as keyof ICampgroundFeatures]),
      id: scenery.feature,
      name: scenery.name,
      bonfireIcon: LOCATION_DETAILS_ICONS[scenery.feature as ELocationDetails],
    }));

    return {
      categories,
      list: [...sceneryList, ...activitesList],
    };
  },
);
