import React from 'react';

import Heading from '@/components/switchback/Heading/Heading';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IAmenity, ICategory } from '@/redux/selectors/listing/amenities';

import ClampModal from '../ClampModal/ClampModal';
import FeatureItem from '../Features/FeatureItem';
interface IProps {
  items: IAmenity[];
  categories: ICategory[];
  modalTitle: string;
  showMoreText: string;
  header?: string;
}

const Amenities: React.FC<IProps> = ({ items, categories, modalTitle, showMoreText, header }) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      {header && (
        <Heading level={4} className="mb-4 font-bold md:h3 text-700 semiHighlight md:mb-6">
          {header}
        </Heading>
      )}

      <ClampModal
        showMoreText={showMoreText}
        lineCount={4}
        isList
        hasMore={items.length > (isMobile ? 5 : 8)}
        modalContent={<AmenitiesModalContent items={items} categories={categories} />}
        modalTitle={modalTitle}>
        <ul className="md:columns-2">
          {items
            .filter(i => i.available)
            .slice(0, isMobile ? 5 : 8)
            .map(item => (
              <li
                key={item.id}
                className={`mb-4 text-base font-normal text-primary-base semiHighlight`}>
                <FeatureItem icon={item.icon} bonfireIcon={item.bonfireIcon} label={item.name} />
              </li>
            ))}
        </ul>
      </ClampModal>
    </>
  );
};

export default Amenities;

interface IModalContentProps {
  items: IAmenity[];
  categories: ICategory[];
}

export const AmenitiesModalContent: React.FC<IModalContentProps> = ({ items, categories }) => {
  if (items.length === 0 || categories.length === 0) {
    return null;
  }
  return (
    <div>
      {categories.map((category, index) => {
        const itemsForCategory = items.filter(item => item.category === category.id);
        if (!itemsForCategory.length) return null;
        // Show available ones first in the modal in each category
        const availableItems = itemsForCategory.filter(i => i.available);
        const unavailableItems = itemsForCategory.filter(i => !i.available);
        const className = `pb-2  mb-2 text-xl font-medium border-gray-700 semiHighlight ${
          index !== 0 ? 'mt-8' : ''
        }`;
        return (
          <div key={category.id}>
            <Heading level={3} className={className}>
              {category.name}
            </Heading>
            <ul>
              {availableItems.map(item => (
                <li
                  key={item.id}
                  className="py-4 text-base border-b border-gray-200 text-primary-base">
                  <FeatureItem icon={item.icon} bonfireIcon={item.bonfireIcon} label={item.name} />
                </li>
              ))}
              {unavailableItems.map(item => (
                <li
                  key={item.id}
                  className="py-4 text-base border-b border-gray-200 text-primary-base">
                  <FeatureItem
                    icon={item.unavailableIcon}
                    bonfireIcon={item.bonfireIcon}
                    label={item.name}
                    unavailable
                  />
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
