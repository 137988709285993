import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveObject } from 'react-slick';

import { IProps as IReviewProps } from '@/components/route/listing/OwnerReviews/Review';
import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel from '@/components/switchback/Carousel/Carousel';
import Heading from '@/components/switchback/Heading/Heading';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';

import PhotoPreviewModal from './PhotoPreviewModal';
import css from './PhotosFromTravelers.module.css';

export interface IReview extends Omit<IReviewProps, 'imagesToShow' | 'onLightboxChange'> {
  id: number;
}

interface IProps {
  reviews: IReview[];
}

const PhotosFromTravelers: React.FC<IProps> = ({ reviews }) => {
  const intl = useIntl();
  const images = reviews.map(review => review.images).flat();

  const [showPreview, setShowPreview] = useState(false);
  const [activeSlide, setActiveSlide] = useState<number | undefined>();
  const [dragging, setDragging] = useState<boolean>(false);

  const title = intl.formatMessage({ defaultMessage: 'Guest photos', id: 'QBALVn' });

  const openPhoto = (image: string) => () => {
    if (!dragging) {
      setActiveSlide(images.indexOf(image));
      setShowPreview(true);
    }
  };

  const responsive: ResponsiveObject[] = [
    {
      breakpoint: 767,
      settings: 'unslick',
    },
  ];

  if (!images.length) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      <Heading level={4} className={css.title}>
        {title}
      </Heading>
      <Carousel
        componentName="gallery-overlay"
        className={`${css.photosList} animate-fadeIn`}
        infinite={false}
        slidesToShow={5}
        slidesToScroll={5}
        responsive={responsive}
        lazyLoad="ondemand"
        beforeSlideChange={() => setDragging(true)}
        afterSlideChange={() => setDragging(false)}
        arrowVariant={[ArrowVariants.inside, ArrowVariants.outside]}>
        {reviews.map((review: IReview) =>
          review.images?.map((image, index) => (
            <ResponsiveImage
              key={image}
              className={css.photo}
              src={image}
              onClick={openPhoto(image)}
              sourceSizes={['square140']}
              sizes="140px"
              alt={intl.formatMessage(
                { defaultMessage: 'Review photo {photoPosition}', id: 'E6Wrwl' },
                { photoPosition: index + 1 },
              )}
            />
          )),
        )}
      </Carousel>

      {showPreview && (
        <PhotoPreviewModal
          open={showPreview}
          reviews={reviews}
          activeSlide={activeSlide}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

export default PhotosFromTravelers;
