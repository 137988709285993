import React from 'react';

import Divider from '@/components/switchback/Divider/Divider';
import { listingPageAnchors } from '@/constants/anchors';
import { REVIEWS_LIMIT } from '@/constants/listing';
import {
  ECampsiteCategoryType,
  IAverageRatings,
  IAverageReviews,
} from '@/services/types/search/rentals/id';

import PhotosFromTravelers from '../PhotosFromTravelers';
import Review, { IProps as IReviewProps } from '../Reviews/Review';
import CategoryReviewsRating from './CategoryReviewsRating';
import ReviewsHeader, { IProps as IReviewsHeaderProps } from './ReviewsHeader/ReviewsHeader';

interface IReview extends Omit<IReviewProps, 'imagesToShow' | 'onLightboxChange'> {
  id: number;
}

interface IProps extends Omit<IReviewsHeaderProps, 'showSeeAllButton'> {
  reviews: IReview[];
  onLightboxChange: (id: number, imageIndex: number | null) => void;
  categoryReviewsRatings?: IAverageReviews;
  ownerAverageRating: IAverageRatings | undefined;
  rentalId?: number;
  isCampground?: boolean;
  isCampsite?: boolean;
  campsiteCategoryType?: ECampsiteCategoryType;
  isStay?: boolean;
}

const Reviews: React.FC<IProps> = ({
  isCampground,
  isCampsite,
  campsiteCategoryType,
  isStay,
  averageRating,
  totalReviews,
  reviews,
  onLightboxChange,
  categoryReviewsRatings,
  ownerAverageRating,
  rentalId,
}) => (
  <div
    className="gap-x-default reset-col-offset lg:grid lg:grid-cols-16"
    id={listingPageAnchors.REVIEWS}>
    <div className="md:col-span-10 lg:col-span-5">
      <ReviewsHeader
        averageRating={averageRating}
        totalReviews={totalReviews}
        showSeeAllButton={totalReviews > reviews.slice(0, REVIEWS_LIMIT).length}
        rentalId={rentalId}
        isCampground={isCampground}
        isCampsite={isCampsite}
        campsiteCategoryType={campsiteCategoryType}
        isStay={isStay}
      />
      {!!categoryReviewsRatings?.owner?.length && !!categoryReviewsRatings?.rental?.length && (
        <Divider className="my-8" />
      )}
      <CategoryReviewsRating
        averageRatings={categoryReviewsRatings}
        ownerAverageRating={ownerAverageRating}
        isStay={isStay}
        isCampground={isCampground || isCampsite}
        reviews={reviews}
      />
    </div>
    <div className="col-span-6 col-start-1 md:col-span-10 lg:col-start-7 lg:col-span-10 mt-14 md:mt-10 lg:mt-0">
      <PhotosFromTravelers reviews={reviews.filter(r => r.images?.length)} />
      {reviews.slice(0, REVIEWS_LIMIT).map((review, index) => (
        <div
          data-testid={`listing-reviews-section-${index + 1}`}
          className="pb-8 mb-8 border-b border-gray-200 border-solid last:pb-0 last:mb-0 last:border-b-0"
          key={review.id}>
          <Review
            name={review.name}
            date={review.date}
            photoUrl={review.photoUrl}
            ratingNumber={review.ratingNumber}
            review={review.review}
            imagesToShow={2}
            images={review.images}
            tags={review.tags}
            ownerAnswer={review.ownerAnswer}
            openedImage={review.openedImage}
            onLightboxChange={imageIndex => onLightboxChange(review.id, imageIndex)}
          />
        </div>
      ))}
    </div>
  </div>
);

export default Reviews;
