import React from 'react';
import { useSelector } from 'react-redux';

import { ownerAverageRating } from '@/redux/modules/owner/selectors';
import {
  getAverageCampgroundReviewsRatings,
  getCampgroundListingId,
} from '@/redux/selectors/campgrounds/page';
import { getCampgroundAllReviews } from '@/redux/selectors/campgrounds/reviews';
import {
  getAverageReviewsRatings,
  getListingCampsiteCategoryType,
  getListingId,
} from '@/redux/selectors/listing/page';
import { getReviewsSummary } from '@/redux/selectors/listing/reviews';
import { handleReviewLighboxChange } from '@/utility/reviews';

import Reviews from './Reviews';

type TReviewsContainerProps = {
  isCampsite?: boolean;
  isStay?: boolean;
  isCampground?: boolean;
};

const ReviewsContainer: React.FC<TReviewsContainerProps> = ({
  isStay,
  isCampground,
  isCampsite,
}) => {
  const reviewsSummary = useSelector(isCampground ? getCampgroundAllReviews : getReviewsSummary);
  const rentalId = useSelector(isCampground ? getCampgroundListingId : getListingId);
  const categoryRatings = useSelector(
    isCampground ? getAverageCampgroundReviewsRatings : getAverageReviewsRatings,
  );
  const campsiteCategoryType = useSelector(
    isCampground ? () => undefined : getListingCampsiteCategoryType,
  );
  const averageRating = useSelector(ownerAverageRating);

  if (!reviewsSummary?.reviews.length) return null;

  return (
    <Reviews
      isCampground={isCampground}
      isCampsite={isCampsite}
      campsiteCategoryType={campsiteCategoryType}
      isStay={isStay}
      rentalId={rentalId}
      averageRating={reviewsSummary.averageRating}
      totalReviews={reviewsSummary.totalReviews}
      reviews={reviewsSummary.reviews}
      onLightboxChange={handleReviewLighboxChange}
      categoryReviewsRatings={categoryRatings}
      ownerAverageRating={averageRating}
    />
  );
};

export default ReviewsContainer;
